span.error_text {
    color: #EB5757;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    margin: 5px 0 0;
    &.warning_text {
        color: #F2994A;
    }
}
.captcha_area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0 27px;
}