.loginPage {
    background: #fff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    .loginCarousel {
        width: 50%;
        background: url(../../images/login-left-bg.jpg) no-repeat top  #101F4F;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: #fff;
        flex-direction: column;
        img.loginLeftImage {
            width: 100%;
            height: auto;
            max-height: calc(100vh - 200px);
            object-fit: contain;
        }
    }
    .loginContent {
        width: 50%;
        padding: 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    
    .loginLogo {
        position: absolute;
        right: 40px;
        top:40px;
    }
    .loginForm {
        width: 100%;
        max-width: 500px;
        margin:0 auto;
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
            font-size: 18px;
            margin: 3px 0 30px;
        }
    }
    .loginFormHead {
        margin: 0 0 40px;
        h2 {
            margin: 0 0 4px;
            font-weight: bold;
            font-size: 42px;
            line-height: 1.3;
            color: #101F4F;
        }
        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.3;
            color: rgba(16, 31, 79, 0.75);
            margin:0;
        }
    }
    .carouselWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        color: #fff;
        text-align: center;
        padding: 10px 50px 50px 50px;
        h2 {
            font-weight: bold;
            font-size: 26px;
            line-height: 1.3;
            margin: 0 0 10px;
        }
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;
            color: rgba(255, 255, 255, 0.75);
            margin:0 auto;
            max-width: 360px;
        }    
        li {
            margin: 0 3px;
            button {
                width: 8px;
                height: 8px;
                background: #FFFFFF;
                opacity: 0.25;
                border-radius: 50%;
                padding:0;
                border:0;
                &::before {
                    display: none;
                }
            }    
        }    
    }
}