.modalContentWrap {
    .tabLinks {
        position: relative;
        background: #fff;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left:-40px;
            top:0;
            width: calc(100% + 80px);
            height: 100%;
            background-color: #fff;
            z-index: 1;
        }
        &::after {
            display: none;
        }
    }
    .cardContentInner {
        max-height: calc(100vh - 350px);
        overflow-y: auto;
        overflow-x: hidden;        
    }
}

.btnWrap {
    padding: 20px;
    position: relative;
    margin-top: -65px;
    z-index: 9999;
    text-align: center;
    button {
        margin: 0 5px;
        width: auto;
        padding: 10px 15px;
        height: auto;
        line-height: 1;
        font-size: 13px;
        font-weight: 500;
        box-sizing: border-box;
        display: inline-block;
        min-height: 0;
        min-width: 0;
        border: none;
        outline: 0;
        vertical-align: middle;
        border-radius: 4px;
        background: #007bff;
        color: #ffffff;
        cursor: pointer;
        &:hover {
            background: rgba(0, 123, 255, 0.06);
            color: #007bff;
        }
    }
}