.coursesWrap {
    .tabContent {
        padding: 40px 30px;
        width: 100%;
        // max-width: 1156px;
        margin: 0 auto;
        > div {
            max-width: none;
        }
    }
    .tabLinks {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        a {
            width: 25%;
            margin: 0;
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.3;
            text-shadow: none !important;
            padding: 24px 12px;
            &::before {
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                position: absolute;
                left:0;
                top:0;
                background: rgba(16, 31, 79, 0.05);
            }
            &:first-child::before {
                display: none;
            }            
            span {
                color: rgba(16, 31, 79, 0.45);
                display: inline-flex;
                align-items: center;
            }
            // &:hover, &:hover span{
            //     color: #101F4F;
            // }
            i {
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                text-align: center;
                line-height: 24px;
                background: rgba(16, 31, 79, 0.1);
                font-weight: 500;
                font-size: 13px;
                color: #101F4F;
                font-style: normal;
                margin-right: 15px;
                text-shadow: none !important;
            }
        }
    }
}

//Course Features
.CourseFeatures {
    h3 {
        margin: 0 0 5px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.3;
        color: #101F4F;
    }
    p {
        font-weight: 500;
        font-size: 13px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.45);
        margin: 0 0 10px;
    }
    ul li.highlight {
        background: rgba(205, 220, 243, 0.22);
    }
}


.addSubjectButton {
    text-align: center;
    border: 2px dashed rgba(16, 31, 79, 0.15);
    border-radius: 6px;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.45);
    padding: 10px;
    margin: 0 0 20px;
}

.courseBulkACtion {
    padding: 0 0 18px;
}

//curriculum chapter list - toggling
.listToggler {
    margin-left: auto;
    width: 16px;
    height: 16px;
    text-align: center;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    img {
        display: block;
        margin-left: auto;
    }
    &.open {
        img {
            transform: rotate(180deg);
        }
    }
}
.chaptersWrap {
    display: block;   
    &.collapsed {
        display: none;
    }
}

.formButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 40px 0 0;
    .button, a {
        margin-left: auto;
        margin-right: auto;
        min-width: 160px;
        min-height: 50px;
    }
}
.pt_0 {
    padding-top: 0 !important;
}
.course_edit_area {
    textarea {
        min-height: 300px;
    }
}