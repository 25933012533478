.rating_area {
    width: 100%;
    display: block;
    position: relative;
}

.ReviewRating {
    display: flex;
    align-items: center;
    margin: 16px 0 0;
    .RatingCount {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        margin-right: 10px;
    }
    .RatingStars {
        display: flex;
        align-items: center;
        i {
            display: inline-block;
            vertical-align: middle;
            margin:0 4px;
            width: 16px;
            height: 16px;
            background-image: url(../../images/icon-star-outline.svg);
            background-repeat: no-repeat;
            background-size: contain;
            &.fill {
                background-image: url(../../images/icon-star-fill.svg);
            }
        }
    }
}