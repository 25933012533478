.field_area {
  width: 100%;
  display: block;
  position: relative;
  margin: 0 0 27px;
  label.label_text {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.75);
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }
  .field_box {
    width: 100%;
    display: block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      display: block;
      background: url('/images/icon-calendar.svg') no-repeat center;
      background-size: 13px;
      right: 15px;
      top: 0;
      z-index: 2;
    }
  }
  .filed_box {
    width: 100%;
    display: block;
    border: solid 1px #efefef;
  }
  button {
    outline: none !important;
  }
}
:global(.react-calendar) {
  border-radius: 10px;
  border: 1px solid #e2e2e2 !important;
}
:global(.react-date-picker) {
  width: 100%;
}
:global(.react-date-picker__inputGroup *) {
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

:global(.react-date-picker__inputGroup) {
  padding: 0 !important;
  > input,
  * {
    padding: 0 !important;
    margin: 0;
    border: none !important;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #101f4f;
  }
  select {
    -webkit-appearance: none;
  }
}
.sm {
  > input,
  * {
    font-size: 13px !important;
    line-height: 28px !important;
    color: #101f4f;
  }
}
:global(.react-daterange-picker__calendar-button) {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2;
}
:global(.react-date-picker__button) {
  display: none;
}
:global(.react-date-picker__button + button) {
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  z-index: 2;
  width: 20px;
  background: transparent;
}
:global(abbr) {
  text-decoration: none !important;
}
.primary {
  :global(.react-date-picker__wrapper) {
    padding: 8px 40px 8px 10px;
    background: rgba(16, 31, 79, 0.04);
    border: 1px solid rgba(16, 31, 79, 0.04);
    border-radius: 10px;
  }
}
.secondary {
  :global(.react-date-picker__wrapper) {
    padding: 8px 40px 8px 10px;
    background: #ffffff;
    border: 1px solid rgba(16, 31, 79, 0.13);
    border-radius: 6px;
  }
}
.tooltipIcon {
  display: block;
  margin: 0 0 0 5px;
}
[disabled] {
  background: transparent ;
  // background: transparent !important;
}
