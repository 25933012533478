.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  min-width: 1200px;
  &.medium {
    min-width: auto;
  }

  th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(16, 31, 79, 0.75);
    text-transform: uppercase;
    text-align: left;
    padding: 13px 0 13px 33px;
  }
  td {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(16, 31, 79, 0.75);
    text-align: left;
    padding: 25px 10px 25px 33px;
    background: #ffffff;
    border-bottom: 1px solid rgba(16, 31, 79, 0.07);
    transition: 0.5s;
    &:first-child,
    &:nth-child(2) {
      color: #101f4f;
    }
  }
  &.grey {
    td {
      background: #f6f7fb;
    }
  }
  tr {
    &:first-child {
      td {
        &:first-child {
          border-radius: 5px 0 0 0;
        }
        &:last-child {
          border-radius: 0 5px 0 0;
        }
      }
    }
    &:last-child {
      td {
        border-bottom: 0;
        &:first-child {
          border-radius: 0 0 0 5px;
        }
        &:last-child {
          border-radius: 0 0 5px 0;
        }
      }
    }
    &:hover {
      td {
        background: rgba(16, 31, 79, 0.02);
        transition: 0.5s;
      }
    }
  }
  &.gapped {
    border-collapse: separate;
    border-spacing: 0 15px;
    tbody tr {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }
    td {
      border: none;
      &:first-child {
        border-radius: 5px 0 0 5px !important;
      }
      &:last-child {
        border-radius: 0 5px 5px 0 !important;
      }
    }
  }
  .actionicon {
    display: inline-block;
    margin: 6px;
    cursor: pointer;
  }
  .listitems {
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    z-index: 2;
    display: none;
    text-align: left;

    .disabled {
      cursor: not-allowed !important;
      pointer-events: none !important;
      img {
        opacity: 0.3;
      }
    }
    .listitem {
      color: rgba(16, 31, 79, 0.75);
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      border-bottom: 1px solid rgba(16, 31, 79, 0.09);
      padding: 9px;
      width: 165px;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        color: #fff;
        background: rgba(16, 31, 79, 0.55);
      }
    }
  }
  tr {
    &:last-child {
      .listitems {
        top: auto;
        bottom: 100%;
      }
    }
  }
  .listouter {
    position: relative;
    &:hover {
      .listitems {
        display: block;
      }
    }
  }
}
.tableouter {
  overflow: auto;

}
.tableactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bulksect {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  margin: 17px 0;
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #101f4f;
    margin-right: 12px;
  }

  .bkbtn {
    margin-left: 8px;
    background: rgba(16, 31, 79, 0.05);
    border-radius: 3px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 50%;
    }
    &.disabled {
      cursor: not-allowed;
      img {
        opacity: 0.3;
      }
    }
  }
}
.selectedmsg {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 13px 16px;
  padding: 11px 16px;
  width: 100%;
  max-width: 462px;
  .msg {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #101f4f;
    display: inline-block;
  }
  .selectall {
    margin-left: 10px;
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: #007bff;
    display: inline-block;
    text-decoration: underline;
  }
}
.tagStyles {
  border-radius: 40px;
  cursor: default;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
  padding: 5px 20px;
  text-transform: capitalize;
  font-weight: 700;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  .modal {
    pointer-events: all;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px rgba(16, 31, 79, 0.1);
    border: 0;
    width: 90%;
    margin: 15px auto;
    padding: 30px;
    max-width: 530px;
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4;
      color: #101f4f;
      margin: 0 0 20px;
      padding: 0;
      text-align: left;
    }
    input[type='text'] {
      padding: 0 15px;
      height: 45px;
      background: rgba(16, 31, 79, 0.04);
      border: 1px solid transparent;
      border-radius: 10px;
      display: block;
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 17px;
      color: #101f4f;
      font-family: inherit;
      font-style: italic;
      line-height: 45px;
      margin: 0 0 20px;
    }
    .button_area {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        padding: 10px 25px;
        border-radius: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        cursor: pointer;
        width: auto;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.45;
        text-align: center;
        outline: none;
        min-width: 127px;
        text-decoration: none !important;
        font-family: inherit;
        height: 40px;
        & + button {
          margin-left: 15px;
        }
        &.cancel {
          background: rgba(0, 123, 255, 0.06);
          color: #007bff;
        }
        &.confirm {
          background: #fd0707e1;
          color: #ffffff;
        }
        &.disabled {
          cursor: not-allowed;
          filter: opacity(0.3);
        }
        &:disabled {
          cursor: not-allowed;
          filter: opacity(0.3);
        }
      }
    }
    .warning {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4;
      color: #f80808;
      margin: 0 0 20px;
      padding: 0;
      text-align: left;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  img {
    opacity: 0.3;
  }
}
.empty {
  width: 100%;
  margin: 0 0 20px;
}
.custom {
  td {
    font-size: 22px !important;
    line-height: 30px !important;
    color: #101f4f !important;
  }
}
.check {
  td:nth-child(3) {
    color: #101f4f !important;
  }
}
.dec {
  color: #101f4f;
  margin: 0;
  opacity: 0.6;
  font-size: 15px;
}
// .tableContainer {
//   width: 100%;
//   height: calc(100vh - 100px);
//   overflow: hidden;
//   table{
//     padding: 0 0 50px;
//   }
// }
