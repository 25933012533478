@import "~@fullcalendar/daygrid/main.css";

body {
  margin: 0;
  font-family: 'HK Grotesk', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, *::before, ::after {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a, a:hover, a:active, a:focus, button, button:hover, button:focus {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}