.disable {
  pointer-events: none;
  cursor: default;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 27px;
  label {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #7a7a7a;
    span {
      color: #f62121;
    }
  }
  .errortext {
    width: 100%;
    position: absolute;
    bottom: 12px;
    left: 0;
    font-size: 12px;
    color: #f62121;
    line-height: 15px;
  }
  .imgContainer {
    position: relative;
    width: 120px;
    height: 120px;
    border: 4px solid #E6E6E6;
    // border-radius: 50%;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      z-index: 3;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    &::before {
      content: url("../../images/camera.svg");
      width: 100%;
      height: 35px;
      position: absolute;
      bottom: 0%;
      left: 0%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(16, 23, 47, 0.35);
      z-index: 2;
      opacity: 0;
      transition: all ease-in-out 0.5s;
    }
    img {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      // border-radius: 50%;
      background: #ffffff;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
  span.error_text {
    color: #EB5757;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    &.warning_text {
        color: #F2994A;
    }
}
}


