body .loginChek + label {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 1.3 !important;            
    color: rgba(16, 31, 79, 0.75) !important;
    &::before {
        background: rgba(16, 31, 79, 0.04);
        width: 24px;
        height: 24px;
    }
    &::after {
        left: 5px;
        top: 7px;
        margin: 0;
        width: 14px;
        height: 7px;
    }
}

ul.BrainhubCarousel__dots {
    margin: 30px 0 0;
}
.BrainhubCarouselItem {
    align-items: flex-start !important;
}

.backToLogin {
    text-align: center;
    a {
        font-weight: 500 !important;
        font-size: 18px !important;
        color: rgba(16, 31, 79, 0.75) !important;
        min-height: 0 !important;
        &:hover {
            color: #007BFF !important;
        }
    }
}