
// inner page top bar
.topBar {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: sticky;
    top:0;
    z-index: 10;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.topBarLeft, .topBarRight {
    display: flex;
    align-items: center;
}

.topBarLeft h4 {
    margin:0 0 0 20px;
    padding:0;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    color: #101F4F;
}

//inner page back button
.topBackButton {
    a, button {
        color: #007BFF;
        font-weight: 600;
        font-size: 14px !important;
        min-width: 0 !important;
        &:hover {
            opacity: 0.7;
        }
    }
}

//innerpage nav
.secondaryNav {
    width: 235px;
    height: calc(100vh - 66px);
    display: block;
    position: fixed;
    left: 275px;
    top: 66px;
    padding: 15px;
    background: #ffffff;
    transition: all linear 0.2s;
}


.innerWrapper {
    display: flex;
    flex-wrap: wrap;
    .mainContent {
        margin-left: 237px;
        width: calc(100% - 237px);
    }
}