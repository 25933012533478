.NavListWrap { 
    margin:0;
    padding:0;
    list-style: none;
    &.divider {
        li {
            position: relative;
            &::after {
                content:'';
                display: block;
               // width: calc(100% + 50px);
                width: 100%;
                height: 1px;
                background: #2b3146;
                position: absolute;
                top:0;
                // left:-25px;
                left:0;
            }
        }
    }
    &.horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
            padding: 0 10px;
        }
        a {
            color: rgba(255, 255, 255, 0.75);
            &:hover {
                color: rgba(255, 255, 255, 0.75);
            }
        }
        &.divider {
            li::after {
                width: 1px;
               // height: calc(100% + 30px);
                left:0;
                //top:-15px;
                height: 100%;
                top:0;
                right:auto;
                bottom: auto;
            }
        }
    }
    &.dark {
        background: #10172F;
        color: rgba(255, 255, 255, 0.75);
    }
    &.light {
        color: rgba(16, 31, 79, 0.75);
        background: #fff;
        font-weight: 500;
        a {
            color: rgba(16, 31, 79, 0.75);
            &:hover {
                color: #007BFF;
                background: rgba(0, 123, 255, 0.08);
            }
            &.current {
                color: #fff;
                background: #007BFF;
            }
        }
        &.divider {
            li::after {
                background: #dddddd;
            }
        }
    }
}

li.navitem {
    padding: 5px 0;
    position: relative;
    transition: all linear 0.5s;
    a {
        display: flex;
        align-items: center;
        padding: 17px;
        color: inherit;
        text-decoration: none;
        transition: all linear 0.5s;
        &.round {
            border-radius: 10px;
        }
        &.square {
            border-radius: 0 !important;
        }
        img {
            width: 16px;
            height: 16px;
            background-size: contain;
            display: block;
            opacity: 0.75;
        }
        span {
            display: block;
            min-width: 100px;
            transition: all linear 0.5s;
        }
        img + span {
            padding-left: 15px;
        }
        &:hover {
            background: #3e5166;
            text-decoration: none;
            color: #fff;
        }
        &.current {
            background: #007BFF;
            text-decoration: none;
            color: #fff;
            img {
                opacity: 1;
            }
        }
        &.current {
            font-weight: bold;
        }
    }
}

.NavListWrap {
    &.plain {
        li a {
            &.current, &.active, &:hover {
                color: #007BFF;
                text-decoration: none;
                background: transparent;
            }
            &:hover img {
                opacity: 0.9;
            }
        }
    }
}
.small {
    li.navitem a {
        padding: 10px 17px;
        transition: all linear 0.5s;
    }
}

.disable{
    opacity: 0.4 !important;
    pointer-events: none !important;
}