.modal__backdrop {
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
}
.modal__wrap {
  background: transparent;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  pointer-events: none;
  font-family: inherit;
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal__inner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 50px 0 0;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.modal__container {
  pointer-events: all;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 24px rgba(16, 31, 79, 0.1);
  border: 0;
  width: 90%;
  margin: 0 auto 50px;
  max-width: 530px;
  padding: 0;
  outline: none;
  position: relative;
  &.square {
    border-radius: 0;
  }
  &.lg {
    max-width: 800px;
  }
  &.md {
    max-width: 916px;
    .ModalContent {
      padding: 0;
      background: #ffffff;
    }
  }
  &.event {
    max-width: 445px;
    .ModalFooter {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 15px 40px 40px;
      button + button {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }
  &.delete {
    max-width: 445px;
    .ModalFooter {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: center;
      padding: 15px 40px 40px;
      button + button {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }
  &.sm {
    max-width: 300px;
  }
  &.xl {
    max-width: 1060px;
    border-radius: 10px;
    .button {
      border-radius: 6px;
      min-width: 112px;
    }
    .ModalFooter {
      padding: 30px 40px 40px;
    }
  }
  &.material {
    .ModalContent {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    .modal__title {
      display: none;
    }
    .closeBtn {
      top: 24px;
      right: 24px;
    }
  }
}

.modal__title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: #101f4f;
  padding: 30px 40px 20px;
  h3 {
    margin: 0;
    padding: 0;
  }
  p {
    margin: 10px 0px;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: rgba(16, 31, 79, 0.75);
  }
  span {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: rgba(16, 31, 79, 0.75);
    &::before {
      content: '';
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      border-radius: 30px;
      background: #eb5757;
      margin: 0 10px 0 0;
    }
  }
}

.button {
  background: rgba(0, 123, 255, 0.1);
  border-radius: 10px;
  border: 0;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  height: 40px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  min-width: 127px;
  padding: 0 15px;
  text-align: center;
  justify-content: center;
  transition: all 0.2s ease;
  outline: none;
  font-family: inherit;
  &:hover {
    background: rgba(0, 123, 255, 0.2);
    transition: all 0.1s ease;
  }
  &.buttonSave {
    background: #007bff;
    color: #fff;
    &:hover {
      background: #0056b3;
      color: #fff;
    }
  }
  &.plain {
    background: transparent;
    border-radius: 0;
    color: #dc3545;
    border: 0;
    font-size: inherit;
    &:hover {
      background: transparent;
      color: #dc3545;
    }
  }
  &.link {
    background: transparent;
    border: none;
    font-family: 'HK Grotesk';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(16, 31, 79, 0.45) !important;
  }
  &.dashboard {
    width: 100%;
    height: 100%;
    padding: 70px 0 0;
    background: transparent;
    font-family: 'HK Grotesk' !important;
    color: rgba(16, 31, 79, 0.45) !important;
  }
  &.disable {
    pointer-events: none;
    background: #b3d7ff !important;
  }
  &.buttonAction {
    display: flex;
    &.primary {
      background: #007bff;
      color: #fff;
      border-radius: 6px;
      &:hover {
        background: #0056b3;
        color: #fff;
      }
    }
    &.plain {
      background: transparent;
      color: inherit;
      border-radius: 0;
      border: 0;
      font-size: inherit;
      // width: 100%;
      &:hover {
        background: transparent;
        color: #000;
      }
    }
    &.icon {
      background: transparent;
      color: inherit;
      border-radius: 0;
      border: 0;
      font-size: inherit;
      width: 100%;
      min-width: 20px;
      padding: 0;
      height: auto;
      span {
        display: none;
      }
      &:hover {
        opacity: 0.75;
      }
    }
    &.link {
      background: transparent;
      padding: 0;
      display: inline !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: rgba(16, 31, 79, 0);
    }
  }
}

.button + .button {
  margin-left: 15px;
}

.ModalContent {
  padding: 0 40px;
  height: auto;
  // width: calc(100% + 40px);
  // margin-left: -20px;
  // margin-right: -20px;
  &:empty {
    padding: 0;
  }
  p {
    margin: 0 0 15px;
    line-height: 1.5;
    &:last-child {
      margin: 0;
    }
    &.center {
      text-align: center;
    }
  }
  // .tabContent {
  //     max-height: calc(100vh - 250px);
  //     overflow-y: auto;
  //     overflow-x: hidden;
  // }
  &.modal__uploader {
    background: #f6f6f6;
  }
}

.ModalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 40px 40px;
  &.reverse {
    flex-direction: row-reverse;
    button + button {
      margin: 0 15px 0 0;
    }
  }
  &.sm {
    button {
      min-width: 80px;
    }
  }
  button {
    &.disable {
      background: #b3d7ff;
    }
  }
}
.closeBtn {
  margin: 0;
  padding: 0;
  border: 0;
  position: absolute;
  right: 40px;
  top: 40px;
  background: url(close-icon.svg) no-repeat center;
  background-size: contain;
  color: #101f4f;
  width: 12px;
  height: 12px;
  cursor: pointer;
  font-size: 0;
  outline: none;
  &:hover {
    opacity: 0.85;
  }
}
.modal_content {
  width: 100%;
  display: block;
  border-top: 1px solid rgba(16, 31, 79, 0.08);
  padding: 20px 30px;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #101f4f;
    padding: 0;
    margin: 0 0 15px;
  }
  ul {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    li {
      width: 100%;
      display: block;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #101f4f;
      padding: 4px 0px;
      &::before {
        content: '';
        position: relative;
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
        background: url('/images/avatar.svg') no-repeat center center;
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(16, 31, 79, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #999;
      outline: none;
      border-radius: 5px;
    }
  }
}

// .disable{
//     pointer-events: none;
//     background-color: #999 !important;
// }
