.sidebar {
  width: 275px;
  background: #10172f;
  display: block;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  transition: all ease 0.5s;
  .sidebar_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: rgba(72, 80, 107, 0.97) rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
  .navWrap {
    flex-grow: 1;
  }
  .copyright {
    background: #1c2647;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    padding: 10px 15px;
    text-align: center;
    color: rgba(255, 255, 255, 0.45);
    transition: all linear 0.5s;
    a {
      color: rgba(255, 255, 255, 0.45);
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(72, 80, 107, 0.97);
    outline: none;
    border-radius: 5px;
    visibility: hidden;
  }
  &:hover {
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      visibility: visible;
    }
  }
  .dashToolItem {
    cursor: pointer;
    padding: 0 15px;
    position: relative;
    .notiCount {
      background: #eb5757;
      border: 2px solid #10172f;
      box-sizing: border-box;
      border-radius: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 50%;
      top: -4px;
      min-width: 22px;
      height: 18px;
      line-height: 1;
      text-align: center;
      font-weight: 900;
      font-size: 10px;
      color: #ffffff;
      transform: translateX(80%);
    }
    & + span {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .sidebarImage {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .dashTools {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 25px;
    transition: all linear 0.5s;
    &.small {
      flex-direction: column-reverse;
      padding: 30px 15px;
      .dashToolItem {
        padding: 0;
        margin: 10px 0;
      }
    }
  }
}
.sidebar_btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(16, 31, 79, 0.15);
  position: absolute;
  right: -11px;
  top: 43px;
  border: none;
  &::before {
    content: '';
    position: relative;
    display: block;
    width: 0;
    height: 0;
    margin: 0 2px 0 0;
    border-right: solid 6px #c4c4c4;
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    transition: all ease-in-out 0.5s;
  }
}
.small {
  .sidebar_btn::before {
    margin: 0 0 0 2px;
    border-left: solid 6px #c4c4c4;
    border-right: none;
  }
}
