.NavBar {
    background:#10172F;
    color: #fff;
    padding: 40px 25px;
    transition: all linear 0.5s;
    &.light {
        color: rgba(16, 31, 79, 0.75);
        background: #fff;
    }
    &.horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 25px;        
    }
    &.vertical {
        max-width: 100%;
    }
    &.inner {
        padding: 30px 16px;
    }
    &.small {
        width: 84px;
        overflow: hidden;
        padding: 40px 15px;
        &.horizontal {
            width: auto;
            padding:15px;
        }
        ul {
            li::after {
                width: calc(100% + 30px);
                left: -15px;
            }
            span {
                display: none;
                min-width: auto;
                transition: all linear 0.5s;
                padding-left: 0;
            }
        }
        ul.horizontal {
            li::after {
                width: 1px;
                height: calc(100% + 30px);
                top: -15px;
                left:auto;
            }
        }
    }
}