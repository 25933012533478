.checkBoxWrap {
    position: relative;
    display: flex;
    align-items: center;
    &.inline {
        display: inline-block;
        vertical-align: middle;
        margin-right: 35px;
    }
    &:last-of-type {
        margin-right: 0 !important;
    }
    input {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        opacity: 0;
        left:0;
        top:0;
        z-index: 1;
        margin:0;
        padding:0;
        cursor: pointer;
    }
    label {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #101F4F;
        display: inline-block;
        padding-left: 35px;
        position: relative;
        min-height: 20px;
        &::before {
            content: '';
            display: block;
            border: 1px solid rgba(16, 31, 79, 0.45);
            border-radius: 3px;
            position: absolute;
            left:0;
            top:0;
            width: 20px;
            height: 20px;
            z-index: 1;
            box-sizing: border-box;
        }
        &::after {
            content:'';
            position: absolute;
            left: 4px;
            top: 5px;
            margin: 0;
            width: 12px;
            height: 6px;
            z-index: 2;
            border-color: #fff;
            border-width: 0 0 2px 2px;
            border-style: solid;
            transform: rotate(-45deg);
            box-sizing: border-box;
            opacity: 0;
        }
        &.square {
            &::before {
                border-radius: 0;
            }
        }
    }
    input:checked + label {
        &::before {
            background: #007BFF;
        }
        &::after {
            opacity: 1;
        }
    }
}

.checkBoxWrap.block + .checkBoxWrap.block {
    margin-top: 20px;
}

.checkBoxWrap {
    &.absolute {
        position: absolute;
        left: 20px;
        top: 15px;
        z-index: 10;
        width: 20px;
        height: 20px;
        // overflow: hidden;
        color: #fff;
        border: 0;
        border-radius: 5px;
        label {
            padding: 0;
            margin: 0;
            font-size: 0;
            display: block;
            color: #fff;
            width: 100%;
            height: 100%;
            min-height: 0;
            box-sizing: border-box;
            line-height: 1;
            &::before {
                border: 1px solid rgba(121, 121, 121, 0.5);
            }
        }
        &:hover {
            label::before {
                border: 1px solid rgba(121, 121, 121, 1);
            }
        }
    }
}