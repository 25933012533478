.blockHead {
    padding: 0 0 20px;
    width: 100%;
    max-width: 1096px;
    margin:0 auto;
    &.flexHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.3;
        color: #101F4F;
        margin: 0 0 5px;
    }
    h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.3;
        color: #101F4F;
        margin: 0 0 5px;
    }
    h4 {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.1;
        color: #101F4F;
        margin: 0 0 5px;
    }
    p {
        margin: 0 0 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: rgba(16, 31, 79, 0.45);
    }
}