$primary: #006aff;
$danger: #dc3545;
$success: #6fcf97;
$warning: #ffc107;
$info: #ddd;
$dark: #000;
$light: #fff;
$secondary: rgba(0, 123, 255, 0.06);

.Progressbar {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
  padding: 0;
  width: 100%;
  height: 18px;
  background: #e7e8ed;
  border-radius: 6px;
  color: #4c577b;
  font-size: 12px;
//   overflow: hidden;
  .Bar {
    float: left;
    position: relative;
    // min-width: 1%;
    height: 100%;
    display: block;
    background: #6fcf97;
    border-radius: 6px;
  }
  .Percent {
    position: absolute;
    // top: 50%;
    right: 0%;
    transform: translateX(110%);
    margin: 0;
  }
  &.right,
  &.left {
    width: calc(100% - 32px);
    overflow: visible;
    .Bar {
      position: static;
    }
    .Percent {
      transform: translateY(-50%);
      left: auto;
      right: -32px;
      font-size: 13px;
    }
  }
  &.left {
    margin-left: auto;
    .Bar {
      float: right;
    }
    .Percent {
      left: -32px;
      right: auto;
    }
  }
  &.xs {
    height: 6px;
    font-size: 8px;
    &.right,
    &.left {
      .Percent {
        font-size: 12px;
      }
    }
  }
  &.sm {
    height: 12px;
    font-size: 10px;
    &.right,
    &.left {
      .Percent {
        font-size: 12px;
      }
    }
  }
  &.lg {
    height: 26px;
    font-size: 14px;
    border-radius: 12px;
    .Bar {
      border-radius: 12px;
    }
    &.right,
    &.left {
      .Percent {
        font-size: 14px;
      }
    }
  }
  &.square {
    border-radius: 0;
    .Bar {
      border-radius: 0;
    }
  }
  &.secondary {
    background: #f5f5f5;
    .Bar {
      background: $secondary;
    }
  }
  &.warning {
    .Bar {
      background: $warning;
    }
  }
  &.success {
    color: #eee;
    .Bar {
      background: $success;
    }
  }
  &.danger {
    color: #eee;
    .Bar {
      background: $danger;
    }
  }
  &.info {
    background: #f5f5f5;
    .Bar {
      background: $info;
    }
  }
  &.dark {
    color: #eee;
    .Bar {
      background: $dark;
    }
  }
  &.light {
    background: #444;
    .Bar {
      background: $light;
    }
  }
  &.striped {
    .Bar {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 1rem 1rem;
    }
  }
  &.animate-striped {
    .Bar {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 1rem 1rem;
      animation: progress-bar-stripes 1s linear infinite;
    }
  }
  &.animate {
    .Bar {
      animation: auto-progress 1.5s forwards linear;
    }
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes auto-progress {
  0% {
    margin-left: -99%;
  }

  100% {
    margin-left: 0;
  }
}
