.block {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 55px 60px;
    width: 635px;
    max-width: 100%;
    margin: 0 auto;
    // height: 100%;
    &.lg {
        padding: 50px;
    }
    &.md {
        padding: 30px 30px;
    }
    &.sm {
        padding: 20px 20px;
    }
    &.xs {
        padding: 5px;
    }
    &.nopadding {
        padding:0;
    }
    &.px0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    &.py0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    &.full {
        max-width: 1096px;
        width: 100%;
    }
    &.max {
        max-width: none;
        width: 100%;
    }
    &.fullheight {
        height: 100%;
    }
    &.border {
        box-shadow: none !important;
        border: 1px solid rgba(16, 31, 79, 0.08);
    }
    &.noborder {
        box-shadow: none !important;
        border: none !important;
    }
}

.block + .block {
    margin-top: 30px;
}