.switchContainer {
    position: relative;
    label {
        font-weight: 500;
        font-size: 15px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        display: flex;
        align-items: center;
        margin: 10px 0 10px;
    }
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        width: 50px;
        height: 26px;
        left: 0;
        cursor: pointer;
        margin: 0;
    }
    input[type="checkbox"]+div {
        vertical-align: middle;
        width: 40px;
        height: 20px;
        border-radius: 999px;
        background-color: #e45757;
        -webkit-transition-duration: .4s;
        transition-duration: .4s;
        -webkit-transition-property: background-color, box-shadow;
        transition-property: background-color, box-shadow;
        cursor: pointer;
    }
    input[type="checkbox"]+div span {
        position: absolute;
        font-size: 1.6rem;
        color: white;
        margin-top: 12px;
    }
    input[type="checkbox"]+div span:nth-child( 1) {
        margin-left: 15px;
    }
    input[type="checkbox"]+div span:nth-child( 2) {
        margin-left: 55px;
    }
    input[type="checkbox"]:checked+div {
        background-position: 0 0;
        background-color: #22702f;
    }
    input[type="checkbox"]+div {
        width: 50px;
        height: 27px;
        padding: 2px;
    }
    input[type="checkbox"]:checked+div {
        background-color: #218f13;
    }
    input[type="checkbox"]+div>div {
        float: left;
        width: 21px;
        height: 21px;
        border-radius: inherit;
        background: #ffffff;
        -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
        transition-timing-function: cubic-bezier(1, 0, 0, 1);
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -webkit-transition-property: transform, background-color;
        transition-property: transform, background-color;
        pointer-events: none;
        margin-top: 1px;
        margin-left: 1px;
    }
    input[type="checkbox"]:checked+div>div {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        background-color: #ffffff;
    }
    input[type="checkbox"].bigswitch+div>div {
        width: 44px;
        height: 44px;
        margin-top: 1px;
    }
    input[type="checkbox"]:checked+div>div {
        -webkit-transform: translate3d(23px, 0, 0);
        transform: translate3d(23px, 0, 0);
    }
}