$primary:#101F4F;
$danger: #dc3545;
$success: #28a745;
$warning: #ffc107;
$info: #bbb;
$dark: #000;
$light: #fff;
$secondary: #007BFF;
$text: rgba(16, 31, 79, 0.75);
.Text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: $primary;
    &.light {
        font-weight: 300;
    }
    &.medium {
        font-weight: 500;
    }
    &.semibold {
        font-weight: 600;
    }
    &.bold {
        font-weight: 700;
    }
    &.lg {
        font-size: 18px;
    }
    &.md {
        font-size: 14px;
    }
    &.sm {
        font-size: 13px;
    }
    &.primary {
        color: $primary;
    }
    &.secondary {
        color: $secondary;
    }
    &.danger {
        color: $danger;
    }
    &.info {
        color: $info;
    }
    &.dark {
        color: $dark;
    }
    &.light {
        color: $light;
    }
    &.success {
        color: $success;
    }
    &.warning {
        color: $warning;
    }
    &.text {
        color: $text;
    }
    &.strike {
        text-decoration: line-through;
    }
    &.underline {
        text-decoration: underline;
    }
    &.overline {
        text-decoration: overline;
    }
    &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {        
        font-weight: bold;
        margin: 0 0 30px;
        &.nomargin {
            margin:0;
        }
        &.small {
            margin:0 0 10px;
        }
        &.medium {
            margin:0 0 20px;
        }
    }
    &.h1 {
        font-size: 32px;
        &.lg {
            font-size: 38px;
        }
    }
    &.h2 {
        font-size: 28px;
        &.lg {
            font-size: 32px;
        }
    }
    &.h3 {
        font-size: 21px;
        &.lg {
            font-size: 24px;
        }
    }
    &.h4 {
        font-size: 18px;
        &.lg {
            font-size: 21px;
        }
    }
    &.h5 {
        font-size: 17px;
        &.lg {
            font-size: 20px;
        }
    }
    &.h6 {
        font-size: 16px;
        &.lg {
            font-size: 19px;
        }
    }
    &.a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    
    &.p {
        line-height: 1.5;
        margin: 0 0 15px;
        &:last-child {
            margin:0;
        }
    }
}
