.ReviewsWrapper {
  .ReviewItem {
    position: relative;
    margin: 0 0 16px;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }
  &.small {
    .ReviewItem {
      margin: 0 0 30px;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      &:last-child {
        margin: 0;
      }
    }
    .ReviewRating {
      .RatingCount {
        font-size: 14px;
        margin-right: 6px;
      }
      .RatingStars {
        i {
          margin: 0 3px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .ReviewContent {
      h4 {
        font-size: 15px;
      }
      .ReviewText {
        font-size: 13px;
      }
    }
    .ReviewProfile {
      .ProfileImage {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
    .ReviewHead {
      .ReviewHeadLeft {
        width: calc(100% - 60px);
      }
      .ReviewHeadRight {
        width: 60px;
        span + span {
          display: none;
        }
        span img {
          max-width: 13px;
        }
      }
    }
  }
  &.medium {
    .ReviewItem {
      margin: 0 0 30px;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 0 0 15px;
      border-bottom: 1px solid rgba(16, 31, 79, 0.08);
      &:last-child {
        margin: 0;
        border: none;
      }
    }
  }
}

.ReviewHead {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ReviewHeadLeft {
    width: calc(100% - 100px);
  }
  .ReviewHeadRight {
    width: 100px;
    text-align: right;
    span {
      display: inline-block;
      img {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      &.flag {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    span + span {
      margin-left: 30px;
    }
  }
}
.ReviewProfile {
  display: flex;
  align-items: center;
  .ProfileImage {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .ProfileDetails {
    .ProfileName {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.3;
      color: #101f4f;
    }
    .ProfileDate {
      font-size: 13px;
      line-height: 1.3;
      color: rgba(16, 31, 79, 0.45);
      margin: 3px 0 0;
    }
  }
}
.ReviewRating {
  display: flex;
  align-items: center;
  margin: 16px 0 0;
  .RatingCount {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.75);
    margin-right: 10px;
  }
  .RatingStars {
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
      width: 16px;
      height: 16px;
      background-image: url(../../images/icon-star-outline.svg);
      background-repeat: no-repeat;
      background-size: contain;
      &.fill {
        background-image: url(../../images/icon-star-fill.svg);
      }
    }
  }
}
.ReviewContent {
  margin: 10px 0 0;
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    color: #101f4f;
    margin: 0;
  }
  .ReviewText {
    margin: 8px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    color: rgba(16, 31, 79, 0.75);
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
.ReviewFooter {
  margin: 20px 0 0;
}

.ProfileImageAddMessage {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
}
.respond_area {
  width: 100%;
  display: block;
  position: relative;
  border-top: 1px solid rgba(16, 31, 79, 0.14);
  padding: 20px 0 0;
  margin: 25px 0 0;
  .respond_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      width: auto;
      display: block;
      font-family: 'HK Grotesk';
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #101f4f;
      margin: 0 0 5px;
    }
    .respond_action {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        cursor: pointer;
      }
      span + span {
        margin-left: 30px;
      }
    }
  }
  p {
    width: 100%;
    display: block;
    margin: 0 0 20px;
    font-family: 'HK Grotesk';
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(16, 31, 79, 0.75);
  }
}
