.logoItem {
    display: block;
    margin: 0 0 20px;
    transition: all linear 1s;
    img {
        display: block;
        margin:0;
        &.logoIcon {
            margin: 0 auto;
        }
    }
    &.inline {
        margin: 0 20px 0 0;
    }
}