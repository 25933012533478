.materialMedia {
    width: 62%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    overflow: hidden;
    border-radius: 10px 0 0 10px;
    min-height: 500px;
    img {
        display: block;
        margin:0;
        width: 100%;
        border-radius: 10px 0 0 10px;
    }
}
.materialInfo {
    width: 38%;
    padding: 40px;
    h4 {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.4;
        color: #101F4F;
        margin: 0 0 5px;
        word-break: break-word;
    }
    .materialDesc {
        font-size: 17px;
        line-height: 1.3;
        color: #101F4F;
        margin: 0 0 7px;
    }
    .materialDate {
        font-size: 13px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.45);
    }
    .materialExcerpt {
        border-top: 1px solid rgba(16, 31, 79, 0.07);
        padding: 12px 0;
        margin: 15px 0 0;
        h6 {
            margin: 0 0 10px;
            font-weight: normal;
            font-size: 14px;
            line-height: 1.3;
            color: rgba(16, 31, 79, 0.45);
        }
        p {
            font-size: 14px;
            line-height: 1.7;
            color: #101F4F;
        }
    }
    .ModalReviewsWrapper {
        max-height: 200px;
        overflow: auto;
        border-top: 1px solid rgba(16, 31, 79, 0.07);
        padding: 20px 0 0;
        margin: 10px 0 0;
    }
}

.AddMaterialContent {
    padding: 40px 30px;
}

body .AddMaterialContent {
    .uppy-Root {
        padding: 0;
    }
    .uppy-Root .uppy-Dashboard-AddFiles-title {
        &::before {
            display: none;
        }
        &::after {
            width: 100%;
            height: 64px;
            background:url(../images/icon-upload.svg) no-repeat bottom center;
            background-size: contain;
        }
        button {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.3;
            color: rgba(16, 31, 79, 0.45);
            box-shadow: none;
            text-align: center;
            border:0;
            text-decoration: none;
        }
    }
    .uppy-Dashboard-inner{
        background: rgba(16, 31, 79, 0.01);
        border: 1px dashed rgba(16, 31, 79, 0.14);
        border-radius: 10px;
    }
}

.formButtonMaterial {
    padding: 40px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.pt-0 {
        padding-top: 0 !important;
    }
}

.BulkActionWrap {
    padding: 25px 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.searchFieldWrap {
    margin-right: 16px;
}