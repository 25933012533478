.dashTableWrapper {
    position: relative;
    margin: 30px 0 0;
    table {
        margin: 0 0 15px;
        th {
            background: #F6F7FB;
            text-transform: unset;
            color: #101F4F;
        }
        td {
            font-size: 13px;
            padding-top: 21px;
            padding-bottom: 21px;
            font-weight: 500;
        }
    }
    h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 1.4;
        color: #101F4F;
        margin: 0 0 20px;
        padding: 23px 33px 0;
    }
}

.pieChartWrap {
    h4 {
        margin: 0 0 15px !important;
    }
    .recharts-wrapper {
        margin: 0 auto 40px;
        position: static !important;
    }
    .recharts-legend-wrapper {
        top: inherit !important;
    }
    li.recharts-legend-item {
        margin-right: 30px !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }
    .recharts-legend-item-text {
        vertical-align: middle;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #101F4F !important;
        margin-left: 3px;
    }
}

.chartHeading {
    padding: 25px 20px 0;
    position: relative;
    h4 {
        margin:0 0 10px !important;
    }
    &.multiLine {
        h4 {
            position: absolute;
            z-index: 1;
        }
    }
}

.chartTabs {
    .tabLinks {
        position: relative;
        display: inline-block;
        border: 1px solid rgba(16, 31, 79, 0.13);
        border-radius: 6px;
        overflow: hidden;
        &::after {
            display: none;
        }
        a {
            min-width: 0 !important;
            margin: 0 0 -1px !important;
            padding: 10px 12px;
            line-height: 1;
            text-shadow: none !important;
            &::before {
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                background: rgba(16, 31, 79, 0.05);
                position: absolute;
                left:0;
                top:0;
            }
            &:first-child::before {
                display: none;
            }
            span {
                font-weight: 500;
                font-size: 13px;
                line-height: 17px;
                color: #101F4F;
                text-shadow: none !important;
                position: relative;
                z-index: 2;
            }
            &::after {
                display: none;
            }
            &[data-class="active"]{
                color: #fff;
                background: #007BFF;
                span {
                    color: #fff !important;
                }
            }
        }
    }
    &.floated {
        .tabLinks {
            position: absolute;
            right: 25px;
            top:20px;
            display: block;
        }
    }
}

.apexcharts-legend-text {
    font-family: inherit !important;
}