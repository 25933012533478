.filter_area {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px;
  .filter_left {
    width: auto;
    display: inline-block;
  }
  .filter_right {
    width: auto;
    display: inline-flex;
  }
}

.notification {
  position: relative;
  right: -320px;
}
