.select_field {
  width: 100%;
  display: block;
  margin: 0 0 27px;
  label.label_text {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.75);
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }
}
.select_area {
  width: 100%;
  display: block;
  position: relative;
  &.md .tab_select {
    height: 45px;
    line-height: 45px;
  }
  .tab_select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: rgba(16, 31, 79, 0.04);
    border: none;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: #101f4f;
    font-family: inherit;
    line-height: 50px;
    border-radius: 10px;
    margin: 0 0 5px;
    padding: 0 15px;
    span.val {
      color: #696969;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 30px);
      text-overflow: ellipsis;
    }
    span.placeholder {
      color: #9f9f9f;
    }
    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 40px;
    }
    > div {
      position: absolute;
      right: 15px;
      width: 10px;
      height: 10px;
      transition: all ease-in-out 0.5s;
      background: url('../../images/icon-drop.svg') no-repeat;
      background-position: center center;
      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    width: 100%;
    display: none;
    background: #fff;
    padding: 0px;
    margin: 4px 0;
    z-index: 10;
    max-height: 205px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    transition: all ease-in-out 0.5s;
    &.list {
      display: block;
    }
    li {
      width: 100%;
      display: block;
      button {
        width: 100%;
        display: block;
        font-family: 'Jost', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-transform: uppercase;
        color: #5a5a5a;
        padding: 8px 12px;
        background: #ffffff;
        border: none;
        text-align: left;
        transition: all ease-in-out 0.5s;
        cursor: pointer;
      }
      &.active {
        button {
          color: #ffffff;
          background: #e31e24;
        }
      }
      &:hover {
        button {
          color: #ffffff;
          background: #007bff;
        }
      }
    }
  }
}

.top {
  > button {
    border-radius: 10px 10px 0 0 !important;
  }
}
.bottom {
  > button {
    border-radius: 0 0 10px 10px !important;
  }
  border: none !important;
}
.border {
  > button {
    border-radius: 10px !important;
  }
  border: none !important;
}
.error_text {
  color: #eb5757;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  &.warning_text {
    color: #f2994a;
  }
}
.error {
  border: 1px solid #eb5757 !important;
}
.tooltipIcon {
  display: block;
  margin: 0 0 0 5px;
}

.outerdiv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(248, 105, 105, 0);
  border: none;
  padding: 0;
}
