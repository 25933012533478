.righBtnsWrap {
    margin-left: 16px;
}
.question_table_block {
    width: 100%;
    display: block;
    position: relative;
}

.reduceMargin {
    margin-left: 240px !important;
}