body .sun-editor {
    border: 0;
    .se-toolbar {
        border: 0;
        outline: none;
        background: #ecedf1;
        border-radius: 10px 10px 0px 0px;
    }
    .se-wrapper .se-wrapper-wysiwyg {
        background: #f5f6f8;
    }
    .se-resizing-bar {
        border-top: 1px dotted #ccc;
        background: #f5f6f8;
        border-radius: 0 0 10px 10px;
        padding: 0 10px;
        .se-navigation {
            font-weight: normal;
        }
    }
}