.select {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 27px;
    &:focus {
        outline: 0;
    }
    &.nomargin {
        margin: 0 !important;
    }
    svg {
        display: block;
        width: 1em;
        height: 1em;
        fill: currentColor;
    }
    .label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: rgba(16, 31, 79, 0.75);
    }
  .selection {
      position: relative;
      background: rgba(16, 31, 79, 0.04);
      border-radius: 10px;
      /* border: 1px solid rgba(16, 31, 79, 0.1); */
      border: 1px solid transparent;
      box-sizing: border-box; 
      padding: 5px;
      min-height: 50px;
      line-height: 1.15;
      font-weight: 500;
      font-size: 16px;
      color: #101F4F;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      outline:none;
  }
  .selection.open {
      /* background: #F5F6F8; */
      border: 1px solid rgba(0, 123, 255, 0.7);
      box-shadow: 0px 2px 3px rgba(0, 123, 255, 0.06);
  }
  .value {
      position: relative;
      display: inline-block;
      padding: 5px 10px;
      font-weight: 400;  
      color: #101F4F;
  }
  .value:not(.multiple){  
      font-size: 16px;
      line-height: 1.25;
  }
  .multiple {
      margin: 2px;
      background:  rgba(16, 31, 79, 0.08);  
      color: #101F4F;
      border-radius: 6px;
      font-size: 13px;
      text-align: left;
      padding: 5px 28px 5px 8px;
  }
  .delete {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      display: block;
      padding: 5px;
      font-size: 8px;
      cursor: pointer;
  }
  .delete:hover {
      color: #000;
  }
  .placeholder {
    color: #101F4F;
    opacity: 0.9;
      padding: 0 10px;
  }
  .arrow {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      font-size: 10px;
      color: #999;
  }
  .options {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      right: 0;
      background: #FFFFFF;
      border: 1px solid rgba(16, 31, 79, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      z-index: 999999;  
  }
  .option {
      padding: 14px 15px;
      border-bottom: 1px solid rgba(16, 31, 79, 0.08);
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: #101F4F;
  }
  .option.selected {
      background: rgba(16, 31, 79, 0.05);
  }
  .option.focused {
      background: rgba(16, 31, 79, 0.04); 
  }
  .checkbox {
      content: '';
      vertical-align: top;
      display: inline-block;
      width: 16px;
      height: 16px;
      padding: 2px;
      border: 1px solid #ddd;
      border-radius: 2px;
      margin: 2px 12px 0 0;
      color: #fff;
      font-size: 10px;
  }
  .selected .checkbox {
      background: #777;
  }
}
.border .selection {
    border: solid 1px rgba(16, 31, 79, 0.13);
    border-radius: 6px;
    background: #ffffff;
}
.md .selection {
    padding: 8px 40px 8px 15px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #101F4F;
    min-height: 45px;
    cursor: pointer;
    letter-spacing: 0px;
    .value {
        padding: 0;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        color: #101F4F;
        letter-spacing: 0px;
    }
    .placeholder {
        opacity: 1;
    }
    * {
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        color: #101F4F;
        letter-spacing: 0px;
    }
}