.filter_tab {
    width: 100%;
    display: block;
    &.pl {
        padding-left: 15px;
    }
    &.pr {
        padding-right: 15px;
    }
    ul {
        width: 100%;
        display: flex;
        padding: 0;
        margin: 0;
        border: solid 1px rgba(16, 31, 79, 0.13);
        border-radius: 6px;
        overflow: hidden;
        li {
            width: 100%;
            display: block;
            padding: 0 !important;
            button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                outline: none;
                font-family: "HK Grotesk";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: #101F4F;
                background: transparent;
                padding: 10px 15px;
                cursor: pointer;
                transition: all ease-in-out 0.2s;
                &:hover {
                    color: #ffffff;
                    background: #007BFF;
                }
                &.active {
                    color: #ffffff;
                    background: #007BFF;
                }
            }
            & + li button {
                border-left: solid 1px rgba(16, 31, 79, 0.05);
            }
        }
    }
}