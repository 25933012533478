.inputWrap {
    position: relative;
    margin: 0 0 27px;
    label.label_text {
        font-weight: 500;
        font-size: 15px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        display: flex;
        align-items: center;
        margin: 0 0 10px;
    }
    span.error_text {
        color: #EB5757;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        &.warning_text {
            color: #F2994A;
        }
    }
    .ForgotPass {
        position: absolute;
        right:0;
        top:0;
        font-size: 16px;
        font-weight: 500;
    }    
    &.big {
        .passInputWrap {
            .passIcon {
                &::before {
                    height: 28px;
                }
                img {
                    width: 24px;
                }
            }
        }
    }
}
.formField {
    padding: 0 15px;    
    height: 50px;
    background: rgba(16, 31, 79, 0.04);
    // background: #f6f6f8;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #101F4F !important;
    font-family: inherit;
    line-height: 50px;
    margin: 0 0 5px;
    &.big {
        font-weight: 500;
        height: 60px;
        line-height: 60px;
    }
    &.md {
        height: 45px;
        line-height: 45px;
    }
    &::placeholder {
        opacity: 0.8;
    }
    &.round {
        border-radius: 10px;
    }
    &.solid {
        border-color:rgba(16, 31, 79, 0.04);
    }
    &:focus {
        outline: none;
        box-shadow: 0px 2px 3px rgba(0, 123, 255, 0.06);
        border-color: rgba(0, 123, 255, 0.7);
    }
    &.error {
        border-color: #EB5757;
    }
    &.warning {
        border-color: #F2994A;
    }
    &:disabled {
        // border-color: rgba(16, 31, 79, 0.1);
        // cursor: not-allowed;
        // background: transparent;
        border: none;
        background: rgba(245, 246, 248, 0.35);
        pointer-events: none;
        cursor: not-allowed;
        // background: rgba(16, 31, 79, 0.15);
        &::placeholder {
            color: rgba(16, 31, 79, 0.35);
            // color: rgba(16, 31, 79, 0.55);
            // opacity:1;
        }
    }
}

.passInputWrap {
    position: relative;
    input {
        padding-right: 45px;
    }
    .passIcon {
        position: absolute;
        top:50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
        line-height: 1;
        display: block;
        &::before {
            content: '';
            display: block;
            width: 1.5px;
            height: 18px;
            transform: translateY(-50%) rotate(-45deg);
            position: absolute;
            top:50%;
            left:0;
            right:0;
            margin:0 auto;
            background:rgba(16, 31, 79, 0.55);
        }
        img {
            display: block;
            width: 16px;
        }
        &[data-state="text"]{
            &::before {
                display: none;
            }
        }
    }
}
.nomargin {
    margin: 0 !important;
}
input.md {
    padding: 8px 15px 8px;
    margin: 0;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #101F4F;
    height: 40px;
    cursor: pointer;
    letter-spacing: 0.5px;
    &:focus {
        border: 1px solid transparent;
    }
}

.tooltipIcon {
    display: block;
    margin: 0 0 0 5px;
}