
// .uppy-Dashboard-progressindicators {
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: #ddd;
//     z-index: 9999;
//     // display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-wrap: wrap;
//   }
.uppy-StatusBar {
    width: 100%;
    text-align: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    transform: translateY(-50%);
    top: 50%;
    background: transparent;
  }

// uppy custom styles
body {
    .uppy-DashboardContent-bar {
        display: none;
    }

    .uppy-Dashboard-innerWrap {
        border-radius: 0;
    }
    .uppy-Dashboard-inner {
        border: 0;
        border-radius: 0;
        background: #f6f6f6;
    }
    .uppy-Root {
        position: relative;
        font-family: inherit;
        padding: 15px 0;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left:-40px;
            top:0;
            width: calc(100% + 80px);
            height: 100%;
            pointer-events: none;
            background: #f6f6f6;
            display: none;
        }
    }
    [data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
        border: 0;
        margin:0;
        height: 100%;
    }
    .uppy-Root .uppy-Dashboard-AddFiles-title {
        position: relative;
        padding-top: 80px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        color: rgba(16, 31, 79, 0.45);
        &::before {
            content: 'Drag And Drop File Here';
            display: block;
            font-weight: 600;
            font-size: 24px;
            line-height: 1.2;
            text-align: center;
            color: #101F4F;
            margin: 0 0 5px;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 60px;
            position: absolute;
            top:0;
            left:0;
            background:url(../images/drag-icon.svg) no-repeat bottom center;
            background-size: contain;
        }
    }    
    .uppy-Root {
        .uppy-Dashboard-Item {
            background: #fff;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
        }
        .uppy-Dashboard-Item-fileInfoAndButtons {
            padding: 12px 20px;
        }
        .uppy-Dashboard-Item-name {
            font-weight: bold;
            font-size: 18px;
            line-height: 1.25;
            color: #101F4F;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .uppy-Dashboard-Item-status {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.3;
            color: #101F4F;
        }
    }
    .uppy-size--xl .uppy-Dashboard-Item {
        width: calc(20% - 15px);
        margin: 15px 15px 0 0;
    }

    .uppy-StatusBar-actions {
        display: none;
    }
    .uppy-StatusBar {
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        transform: none;
        &[aria-hidden="true"] {
            opacity: 0;
            pointer-events: none;
        }
    }
    .uppy-StatusBar::before {
        height: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10px;
        background: rgba(27, 39, 51, 0.1);
        z-index: 2;
    }
    
    .uppy-StatusBar::after {
        content:'';
        display: block;
        width: 100%;
        height: 100%;
        background:#f6f6f6;
        position: absolute;
        left:0;
        top:0;
    }
    .uppy-StatusBar-content {
        margin-top: 45px;
        height: auto;
    }
    .uppy-StatusBar-progress {
        height: 8px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10px;
        z-index: 3;
        background: #006AFF;
    }
    .uppy-size--md .uppy-Dashboard-AddFiles-title button {
        // position: absolute;
        // left: 0;
        // top: 0;
        width: auto;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        margin: 0;
        position: relative;
    }
}
.uppy-Dashboard-Item-fileInfo {
    overflow: hidden;
    text-overflow: ellipsis;
}
div[role="presentation"] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}