.more_option_area {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.absolute {
        position: absolute;
        top: 15px;
        right: 20px;
        // z-index: 10;
    }    
    &.fixed {
        position: fixed;
    }
    .more_btn {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.3) url("../../images/moreactionicon.svg") no-repeat center center;
        background-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 4px;
        border: none;
        filter: brightness(10);
    }
    ul {
        position: absolute;
        top: 100%;
        right: 0%;
        min-width: 120px;
        max-width: 175px;
        padding: 5px 0;
        margin: 5px 0 0;
        background: #ffffff;
        border: 1px solid #f3f3f3;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        z-index: 99999;
        li {
          width: 100%;
          display: block;
          button {
            width: 100%;
            height: auto;
            display: block;
            text-align: left;
            font-family: 'HK Grotesk';
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #5a5a5a;
            padding: 5px 20px;
            background: transparent;
            border: none;
            white-space: nowrap;
            transition: all ease-in-out 0.5s;
            &:hover {
              color: #302d4e;
            }
          }
        }
      }
      &.last {
        ul {
          top: inherit;
          bottom: 0;
        }
      }
}