.notiWrapper {
  position: relative;
  .notiHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 0 15px;
  }
  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    color: #101f4f;
    padding-right: 10px;
  }
  .notiMore {
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.3;
      color: #007bff;
      &:hover {
        text-decoration: underline;
      }
    }
    &.bottom {
      border-top: 1px solid rgba(16, 31, 79, 0.07);
      padding: 20px 0 0;
      text-align: center;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      padding: 14px 25px 14px 0;
      border-bottom: 1px solid rgba(16, 31, 79, 0.07);
      &:last-child {
        border: 0;
      }
    }
  }
  .notiContent {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 75px);
    .notiImg {
      width: 40px;
      // min-width: 40px;
      height: 40px;
      text-align: center;
      img {
        //     display: inline-block;
        //     vertical-align: middle;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
      }
    }
    .notiText {
      margin-left: 10px;
    }
    .notiTitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
      color: #101f4f;
      &.notiTitleAnnounce {
        font-size: 14px;
        line-height: 1.55;
        letter-spacing: 0.005em;
      }
    }
    .notiDesc {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      color: rgba(16, 31, 79, 0.45);
      margin: 3px 0 0;
      &:empty {
        display: none;
      }
    }
  }
  .notiTime {
    position: absolute;
    right: 0;
    top: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: rgba(16, 31, 79, 0.45);
  }
}
