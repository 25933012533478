.searcFieldWrap {
    position: relative;   
    padding: 10px 0; 
    width: 100%;
    max-width: 216px;
    &.full {
        max-width: 100%;
    }
    .searchIcon {
        display: block;
        position: absolute;
        left: 17px;
        width: 18px;
        top: 50%;
        transform: translateY(-50%);
        img {
            display: block;
        }
        input {
            background: transparent;
            opacity: 1;
            z-index: 2;
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 0;
            cursor: pointer;
        }
    }
    .SearchField {
        padding: 0 15px;    
        height: 50px;
        background: rgba(255, 255, 255, 0.15);
        border: 0;
        display: block;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.75);    
        font-family: inherit;
        line-height: 50px;
        &.big {
            font-weight: 600;
            font-size: 16px;
            height: 60px;
            line-height: 60px;
        }
        &::placeholder {
            color: rgba(255, 255, 255, 0.75); 
        }
        &.round {
            border-radius: 10px;
        }
        &.solid {
            border:1px solid rgba(16, 31, 79, 0.04);
        }
        &:focus {
            outline: none;
            box-shadow: 0 0 5px #ddd;
        }
        &.error {
            border: 1px solid #EB5757;
        }
    }
    &.light {
        .SearchField {
            background: rgba(16, 31, 79, 0.03);
            color: rgba(16, 31, 79, 0.75);
            &::placeholder {
                color: rgba(16, 31, 79, 0.75);
            }
        }

    }
    &.white {
        .SearchField {
            background: #fff;
            color: #101F4F;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            height: 40px;
            font-weight: 500;
            &::placeholder {
                color: rgba(16, 31, 79, 0.45);
            }
        }
        .searchIcon {
            left: 11px;
            width: 12px;
        }
        .searchIcon + .SearchField {
            padding-left: 32px;
            padding-right: 30px;
        }
    }
}


.searchIcon + .SearchField {
    padding-left: 50px;
}

.small {
    .searchIcon {
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .searchIcon + .SearchField {
        padding-left: 35px;
    }
}
.loading {
    background-image: url(../../images/spinner.gif);
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center;
    width: 30px;
    position: absolute;
    height: 40px;
    top: 10px;
    right: 0px;
  }
  