.tabLinks {
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        left:0;
        bottom:0;
        height: 1px;
        background: rgba(16, 31, 79, 0.1);
    }
}