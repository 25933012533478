.field_area {
    width: 100%;
    display: block;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 100%;
        display: block;
        background: url("/images/icon-calendar.svg") no-repeat center;
        left: 15px;
        top: 0;
        z-index: 1;
    }
    label.label_text {
        font-weight: 500;
        font-size: 15px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        display: block;
        margin: 0 0 10px;
    }
    .filed_box {
        width: 100%;
        display: block;
        border: solid 1px #efefef;
    }
    button {
        outline: none !important;
    }
}
:global(.react-daterange-picker) {
    width: 100%;
}
:global(.react-daterange-picker__inputGroup__input) {
    &:focus {
        border: none !important;
        outline: none !important;
    }
}

:global(.react-daterange-picker__inputGroup) {
    padding: 0 !important;
    > input, * {
        padding: 0 !important;
        margin: 0;
        border: none !important;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 28px;
        color: #101F4F;
    }
    select {
        -webkit-appearance: none;
    }
}
:global(.react-daterange-picker__calendar-button),
:global(.react-daterange-picker__clear-button) {
    display: none;
}

.primary {
    :global(.react-daterange-picker__wrapper) {
        padding: 8px 10px 8px 40px;
        background: rgba(16, 31, 79, 0.04);
        border: 1px solid rgba(16, 31, 79, 0.04);
        border-radius: 10px;
    }
}
.secondary {
    :global(.react-daterange-picker__wrapper) {
        padding: 8px 10px 8px 40px;
        background: #FFFFFF;
        border: 1px solid rgba(16, 31, 79, 0.13);
        border-radius: 6px;
    }
}