.file_field {
    width: 100%;
    height: 150px;
    display: block;
    margin: 0 0 100px;
    position: relative;
    label.fileLabel {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        display: block;
        margin: 0 0 10px;
    }
    .import_upload {
        width: 100%;
         height: 200px;
        background: #ffffff;
        border: 1.5px dashed rgba(16, 31, 79, 0.2);
        border-radius: 6px;
        position: relative;
        .custom_upload {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            padding: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
                width: 65px;
                height: 65px;
                display: block;
                margin: 0 0 25px;
            }
            h4 {
                font-weight: 500;
                font-size: 16px;
                line-height: 124%;
                letter-spacing: 0.005em;
                color: #101F4F;
                margin: 0 0 7px;
                span {
                    color: #007BFF;
                }
            }
            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 124%;
                letter-spacing: 0.005em;
                color: rgba(16, 31, 79, 0.45);
                margin: 0;
            }
        }
        .upload_area{
            width: "100%";
		    height: "280px";
        }
        
    }
    span.error_text {
        color: #EB5757;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        &.warning_text {
            color: #F2994A;
        }
    }
}

:global(body .uppy-Root) {
    padding: 0px !important;
}
:global(body .uppy-Dashboard-inner) {
    background: transparent !important;
}
// :global(.uppy-Dashboard-AddFiles) {
//     opacity: 0;
// }
:global(body .uppy-StatusBar::after) {
    background: #F6F6F6;
}
:global(.uppy-StatusBar:not([aria-hidden=true]).is-waiting) {
    border: none;
}
// :global(body .uppy-Root .uppy-Dashboard-AddFiles-title) {
//     position: static !important;
// }
:global(.uppy-Dashboard-browse) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
}
:global(.uppy-Dashboard-files) {
    background: #F6F6F6 !important;
}