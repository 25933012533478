ul.drodownList {
    background: #FFFFFF;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    list-style: none;
    margin: 0;
    padding: 10px 0px 5px;
    max-height: 490px;
    overflow: auto;
    text-align: left;
    scrollbar-color: rgba(210, 212, 219, 0.3) rgba(248, 235, 235, 0.3);
    scrollbar-width: thin;
    position: absolute;
    right: 0;
    top: calc(100% + 20px);
    z-index: 999;
    width: 100%;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: none;
        background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border: solid 2px #f5f5f5;
        outline: none;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        border: solid 2px #d4d4d4;
    }
    .noti_area {
        padding: 10px 25px;
    }
    li {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.45;
        color: #101F4F;
        padding: 15px 25px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        &:last-child {
            border:0;
        }
        &:hover {
            background:#fafafa;
        }
    }
}

.dropdownListWrap {
    width: 345px;
    max-width: 50%;
    text-align: right;
    position: relative;
}

.dropdownToggler {
    position: relative;
    &::before, &::after {
        content: '';
        display: block;
        clear: both;
    }
    span {
        display: block;
        max-width: 100%;
        float: right;
        line-height: 1;
        padding-right: 20px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        i {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.4;
            color: #101F4F;
        }
        img {
            display: block;
            width: 10px;
            height: 10px;
            object-fit: contain;
            position: absolute;
            right: 0;
            top:10px;
            transition: 0.2s ease;
        }
    }
    .dropShow {
        display: block;
    }
    .dropHide {
        display: block;
        transform: rotate(180deg);
    }
}
img.togglerImg {
    width: 25px !important;
    height: 25px !important;
    display: block !important;
    position: relative !important;
    top: 0 !important;
}
