//fonts
@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Medium.woff2') format('woff2'),
        url('fonts/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
        url('fonts/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Regular.woff2') format('woff2'),
        url('fonts/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Bold.woff2') format('woff2'),
        url('fonts/HKGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Black.woff2') format('woff2'),
        url('fonts/HKGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-ExtraBold.woff2') format('woff2'),
        url('fonts/HKGrotesk-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Italic.woff2') format('woff2'),
        url('fonts/HKGrotesk-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-Light.woff2') format('woff2'),
        url('fonts/HKGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('fonts/HKGrotesk-LightItalic.woff2') format('woff2'),
        url('fonts/HKGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
//END fonts

/* ion icons */
// @import 'http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css';

//base styles
@import "src/styles/base.scss";

//sidebar styles
@import "src/styles/sidebar.scss";

//main content area styles
@import "src/styles/main.scss";

//uppy plugin custom styles
@import "src/styles/uppy.scss";

//page title
@import "src/styles/page-title.scss";

//tabs
@import "src/styles/tabs.scss";

//cards
@import "src/styles/card.scss";

//modal
@import "src/styles/modal.scss";

//course page
@import "src/styles/course.scss";

//block
@import "src/styles/block.scss";

//inner page base styles
@import "src/styles/inner-page-base.scss";

//material page styles
@import "src/styles/material-page.scss";

//editor styles
@import "src/styles/editor.scss";

// login page styles
@import "src/styles/login.scss";

// dashboard home styles
@import "src/styles/dashboard.scss";