.loader_area {
    width: calc(100% - 275px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 275px;
    top: 0;
    z-index: 1000;
    background: #f1f2f3;
    img {
        width: 58px;
        height: 58px;
        display: block;
        // transition: all linear 0.5s;
        // animation: Rotate 2s linear 0s infinite;
    }
}
@keyframes Rotate {
    0% {
        transform: rotate(-360deg);
    }
}