.pageWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  transition: all linear 0.2s;
  &.small {
    .sidebar {
      width: 85px;
      transition: all linear 0.2s;
    }
    .main {
      padding-left: 85px;
      transition: all linear 0.2s;
      .lg {
        left: 85px;
      }
      .large {
        width: calc(100% - 85px);
        left: 85px;
      }
    }
  }
}
.main {
  width: 100%;
  padding-left: 275px;
  position: relative;
  z-index: 1;
  transition: all linear 0.5s;
  .mainContent {
    padding: 40px 30px;
    &.p0 {
      padding: 0;
    }
  }
  .blankDash {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    h1 {
      margin: 20px 0 5px;
      padding: 0;
    }
    p {
      padding: 0 0 10px;
    }
  }
}
.table_area {
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 0 0;
}
.form_area {
  width: 100%;
  max-width: 510px;
  display: block;
  position: relative;
}
.App {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 9999;
}
.no_data {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-family: 'HK Grotesk';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(16, 31, 79, 0.35);
    margin: 20px 0 0;
  }
}
