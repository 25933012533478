.textAreaWrap {
    position: relative;
    margin: 0 0 27px;
    label.label_text {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        color: rgba(16, 31, 79, 0.75);
        display: block;
        margin: 0 0 10px;
        text-transform: capitalize;
    }
    span.error_text {
        color: #EB5757;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        &.warning_text {
            color: #F2994A;
        }
    }
}
.formField {
    padding: 15px;    
    min-height: 125px;
    background: rgba(16, 31, 79, 0.04);
    border: 1px solid transparent;
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #101F4F;
    font-family: inherit;
    margin: 0 0 5px;
    resize: none;
    overflow-x: hidden;
    &.big {
        font-weight: 500;
        min-height: 150px;
    }
    &::placeholder {
        opacity: 0.8;
    }
    &.round {
        border-radius: 10px;
    }
    &.solid {
        border-color:rgba(16, 31, 79, 0.04);
    }
    &:focus {
        outline: none;
        box-shadow: 0px 2px 3px rgba(0, 123, 255, 0.06);
        border-color: rgba(0, 123, 255, 0.7);
    }
    &.error {
        border-color: #EB5757;
    }
    &.warning {
        border-color: #F2994A;
    }
    &:disabled {
        border-color: rgba(16, 31, 79, 0.1);
        cursor: not-allowed;
        background: transparent;
        &::placeholder {
            color: rgba(16, 31, 79, 0.35);
            opacity:1;
        }
    }
}
.formField::-webkit-scrollbar {
    width: 20px;
}
.formField::-webkit-scrollbar-track {
    box-shadow: none;
}
.formField::-webkit-scrollbar-thumb {
    background-color: rgba(16, 31, 79, 0.15);
    outline: none;
    border: solid 9px #F5F6F8;
    border-radius: 10px;
}
.formField:hover::-webkit-scrollbar-thumb {
    border: solid 7px #F5F6F8;;
}
