.modalContainer {
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  .modal {
    pointer-events: all;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px rgba(16, 31, 79, 0.1);
    border: 0;
    width: 90%;
    margin: 15px auto;
    padding: 30px;
    max-width: 530px;
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4;
      color: #101f4f;
      margin: 0 0 20px;
      padding: 0;
      text-align: left;
    }
    input[type='text'] {
      padding: 0 15px;
      height: 45px;
      background: rgba(16, 31, 79, 0.04);
      border: 1px solid transparent;
      border-radius: 10px;
      display: block;
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 17px;
      color: #101f4f;
      font-family: inherit;
      font-style: italic;
      line-height: 45px;
      margin: 0 0 20px;
    }
    .button_area {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        padding: 10px 25px;
        border-radius: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        cursor: pointer;
        width: auto;
        font-weight: bold;
        font-size: 100%;
        line-height: 1.45;
        text-align: center;
        outline: none;
        min-width: 127px;
        text-decoration: none !important;
        font-family: inherit;
        height: 40px;
        & + button {
          margin-left: 15px;
        }
        &.cancel {
          background: rgba(0, 123, 255, 0.06);
          color: #007bff;
        }
        &.confirm {
          background: #fd0707e1;
          color: #ffffff;
        }
        &.disabled {
          cursor: not-allowed;
          filter: opacity(0.3);
        }
        &:disabled {
          cursor: not-allowed;
          filter: opacity(0.3);
        }
      }
    }
    .warning {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4;
      color: #f80808;
      margin: 0 0 20px;
      padding: 0;
      text-align: left;
    }
  }
}
