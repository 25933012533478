.cardsWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 30px 0 0;
  &.false {
    padding: 0;
  }
  &[data-gap='10'] {
    margin-left: -10px;
    margin-right: -10px;
    .card {
      padding: 0 10px 20px;
    }
  }
  .card {
    position: relative;
    width: 100%;
    padding: 0 15px 30px;
  }
  &[data-row='4'] {
    .card {
      width: 25%;
    }
  }
  &[data-row='3'] {
    .card {
      width: 33.33%;
    }
  }
  &[data-row='2'] {
    .card {
      width: 50%;
    }
  }
  .cardInner {
    position: relative;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
  .border {
    box-shadow: none !important;
    border: 1px solid rgba(16, 31, 79, 0.08);
    &:hover {
      box-shadow: none;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    .cardMedia {
      padding: 15px 0 15px 20px;
      position: relative;
      height: auto;
      .flex_img {
        width: 45px;
        height: 45px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50% !important;
        background: rgba(16, 31, 79, 0.08);
      }
      img {
        width: auto !important;
        height: auto !important;
        display: block;
        border-radius: 0 !important;
      }
      // &::before {
      //     content: '';
      //     width: 45px;
      //     height: 45px;
      //     position: absolute;
      //     left: 20px;
      //     top: 15px;
      //     border-radius: 50% !important;
      //     background: rgba(16, 31, 79, 0.08);
      // }
    }
    .cardContent {
      h4 {
        font-weight: bold;
        font-size: 30px;
        line-height: 39px;
      }
      .cardDesc {
        opacity: 0.45;
        font-weight: 500;
      }
    }
  }
  .cardMedia {
    position: relative;
    height: 185px;
    overflow: hidden;
    > button {
      height: 100% !important;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 6px 6px 0 0;
    }
    .xs {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background: rgba(16, 31, 79, 0.08);
      border-radius: 50%;
    }
  }
  .cardContent {
    position: relative;
    padding: 15px 20px;
    &.counter {
      padding: 20px 30px;
      .cardTitle {
        font-size: 24px;
        margin: 0 0 6px;
      }
      .cardDesc {
        font-size: 12px;
        margin: 0;
        font-weight: 600;
      }
    }
  }
  .cardTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 3px;
    color: #101f4f;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cardDesc {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #101f4f;
    margin: 0 0 3px;
  }
  .cardDate {
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(16, 31, 79, 0.45);
  }
  .cardStatus {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 2;
    span {
      background: #eb5757;
      border-radius: 40px;
      font-weight: bold;
      font-size: 10px;
      line-height: 1;
      color: #ffffff;
      padding: 5px 10px;
      text-transform: capitalize;
      height: 22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cardSize {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
    span {
      height: 22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #000000;
      border-radius: 3px;
      line-height: 1;
      padding: 5px 8px;
      font-weight: 600;
      font-size: 11px;
      color: #f0f7ff;
    }
  }
  .cardLength {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 2;
    span {
      height: 22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #000000;
      border-radius: 3px;
      line-height: 1;
      padding: 5px 8px;
      font-weight: 600;
      font-size: 11px;
      color: #f0f7ff;
    }
  }
  .check {
    input {
      opacity: 0;
      &:checked {
        opacity: 1;
      }
    }
  }
  .cardInner:hover {
    .check input {
      opacity: 1;
    }
  }
}
.chart_area {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 100%;
}
.featured {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 130px;
  left: 20px;
  background: #007bff;
  border-radius: 40px;
  padding: 5px 15px;
  font-family: 'HK Grotesk';
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.free {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 130px;
  right: 15px;
  background: #007bff;
  border-radius: 40px;
  padding: 5px 15px;
  font-family: 'HK Grotesk';
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.outerdiv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(248, 105, 105, 0);
  border: none;
  padding: 0;
  z-index: 8;
}
// .custom {
//   z-index: 10;
// }
