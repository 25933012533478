.modal__backdrop {
	background: rgba(0, 0, 0, .45);
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 998;
    width: 100vw;
    height: 100vh;
}

.modal__wrap {
	background: transparent;
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
    pointer-events: none;
    font-family: inherit;
    text-align: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.modal__inner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 50px 0 0;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.modal__container {
    pointer-events: all;
	background:#fff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px rgba(16, 31, 79, 0.1);
    border:0;
    width: 90%;
    margin:0 auto 50px;
    max-width: 530px;
    padding: 0;
    outline: none;
    position: relative;
    &.square {
        border-radius: 0;
    }
    &.lg {
        max-width: 800px;
    }
    &.sm {
        max-width: 300px;
    }
    &.xl {
        max-width: 1060px;
        border-radius: 10px;
        .button {
            border-radius: 6px;
            min-width: 112px;
        }
        .ModalFooter {
            padding: 30px 40px 40px;
        }
    }
    &.material {
        .ModalContent {
            padding:0;
            display: flex;
            flex-wrap: wrap;
        }        
        .modal__title {
            display: none;
        }
        .closeBtn {
            top: 24px;
            right: 24px;
        }
    }
}

.modal__title {
    margin:0;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.3;
    color: #101F4F;
    padding: 30px 40px 20px;
}



.button {
    background: rgba(0, 123, 255, 0.1);
    border-radius: 10px;
	border: 0;
	color: #007BFF;
	cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    height: 40px;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 127px;
    padding: 0 15px;
    text-align: center;
    justify-content: center;
    transition: all 0.2s ease;
    outline: none;
    font-family: inherit;
	&:hover {
		background: rgba(0, 123, 255, 0.2);
        
        transition: all 0.1s ease;
	}
    &.buttonSave {
        background: #007BFF;
        color: #fff;
        &:hover {
            background: #0056B3;
            color: #fff;
        }
    }
    &.buttonAction {
        display: flex;
        &.primary {
            background: #007BFF;
            color: #fff;
            border-radius: 6px;
            &:hover {
                background: #0056B3;
                color: #fff;
            }
        }
        &.plain {
            background: transparent;
            color: inherit;
            border-radius: 0;
            border:0;
            font-size: inherit;
            width: 100%;
            &:hover {
                background: transparent;
                color: #000;
            }
        }
        &.icon {
            background: transparent;
            color: inherit;
            border-radius: 0;
            border:0;
            font-size: inherit;
            width: auto;
            min-width: 20px;
            padding:0;
            height: auto;
            span {
                display: none;
            }
            &:hover {
                opacity: 0.75;
            }
        }
    }
}

.button + .button {
    margin-left: 15px;
}

.ModalContent {
    padding: 0 40px;
    height: auto;
    // width: calc(100% + 40px);
    // margin-left: -20px;
    // margin-right: -20px;
    &:empty {
        padding:0;
    }
    p {
        margin: 0 0 15px;
        line-height: 1.5;
        &:last-child {
            margin:0;
        }
    }
    // .tabContent {        
    //     max-height: calc(100vh - 250px);
    //     overflow-y: auto;
    //     overflow-x: hidden;
    // }
    &.modal__uploader {
        background: #F6F6F6;
    }
}

.ModalFooter {
    text-align: right;
    padding: 15px 40px 40px;
}
.closeBtn {
    margin:0;
    padding:0;
    border:0;
    position: absolute;
    right:40px;
    top:40px;
    background: url(close-icon.svg) no-repeat center;
    background-size: contain;
    color: #101F4F;
    width: 12px;
    height: 12px;
    cursor: pointer;
    font-size: 0;
    outline: none;
    &:hover {
        opacity: 0.85;
    }
}