html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    outline: none !important;
}

img {
    max-width: 100%;
}

body {
    margin: 0;
    font-family: 'HK Grotesk', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f6f7fb;
    color: #101F4F;
    scrollbar-color: rgba(210, 212, 219, 0.3) rgba(248, 235, 235, 0.3);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
        // visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #999;
        outline: none;
        border-radius: 5px;
        // visibility: hidden;
    }
    // &:hover {
    //     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
    //         visibility: visible;
    //     }
    // }
}

input::-ms-reveal, input::-ms-clear {
    display: none !important;
}

div,body, html {
    scrollbar-color: rgba(210, 212, 219, 0.3) rgba(248, 235, 235, 0.3);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
        visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #999;
        outline: none;
        border-radius: 5px;
        visibility: hidden;
    }
    &:hover {
        &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
            visibility: visible;
        }
    }
}

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}

a {
    text-decoration: none;
    color: #007BFF;
    transition: all 0.3s ease;
  }
  a:hover {
    /* color: #101F4F; */
    text-decoration: underline;
    transition: all 0.3s ease;
  }

.loadingScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: #F1F2F3;
    display: flex;
    padding-left: 275px;
    align-items: center;
    justify-content: center;
    color: #aaa;
}

.errorScreen {
    text-align: center;
    padding: 30px;
    color: #aaa;
}

// cols
.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;    
    &.reverse {
        flex-direction: row-reverse;
    }
    &.column {
        flex-direction: column;
    }
    [class^=col-] {
        padding: 0 10px;
    }
    .col-10 {
        width: 10%;
    }
    .col-20 {
        width: 20%;
    }
    .col-25 {
        width: 25%;
    }
    .col-30 {
        width: 30%;
    }
    .col-33 {
        width: 33.333%;
    }
    .col-35 {
        width: 35%;
    }
    .col-40 {
        width: 40%;
    }
    .col-50 {
        width: 50%;
    }
    .col-60 {
        width: 60%;
    }
    .col-66 {
        width: 66.666%;
    }
    .col-65 {
        width: 65%;
    }
    .col-70 {
        width: 70%;
    }
    .col-75 {
        width: 75%;
    }
    .col-80 {
        width: 80%;
    }
    .col-90 {
        width: 90%;
    }
    .col-100 {
        width: 100%;
    }
}
.m0 {
    margin: 0;
}
.mt0 {
    margin: 0;
}
.ml0 {
    margin: 0;
}
.mb0 {
    margin: 0;
}
.mr0 {
    margin: 0;
}
.mt30 {
    margin-top: 30px;
}
.ml30 {
    margin-left: 30px;
}
.mb30 {
    margin-bottom: 30px;
}
.mr30 {
    margin-right: 30px;
}
label {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.75);
    display: block;
    margin: 0 0 10px;
}