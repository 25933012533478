//page title sec
.pageTitleSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 20px;
  align-items: center;
  &.pb-30 {
    padding-bottom: 30px;
  }
  h1 {
    margin: 0 !important;
  }
  h2 {
    margin: 0 !important;
  }
  .pageTitleDesc {
    margin: 5px 0 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    color: rgba(16, 31, 79, 0.75);
  }
  .pageTitleRight {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    button {
      border-radius: 6px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    div + button {
      margin-left: 15px;
    }
    // img {
    //   // position: absolute;
    //   // right: 150px;
    // position: relative;
    // right: -320px;
    // }
  }
}
