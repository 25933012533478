.select_area {
    width: 100%;
    display: block;
    position: relative;
    .tab_select {
        width: 100%;
        display: block;
        background: rgba(16, 31, 79, 0.04);
        border-radius: 10px;
        padding: 14px 16px;
        border: none;
        height: 50px;
        border: 1px solid transparent;
        text-align: left;
        position: relative;
        &:focus{
            outline: none;
            box-shadow: 0px 2px 3px rgb(0 123 255 / 6%);
            border-color: rgba(0, 123, 255, 0.7);
        }
        span.val {
          font-size: 16px;
          color: #101F4F;
        }
        span.placeholder {
            font-size: 16px;
            color: #9F9F9F;
        }

      > div {
        position: absolute;
        right: 15px;
        top: calc(50% - 5px);
        width: 10px;
        height: 10px;
        transition: all ease-in-out 0.5s;
        background: url("../../images/list-toggler.svg") no-repeat;
        background-position: center center;
        &.rotate {
            transform: rotate(-180deg);
        }
      }
    }
  
    ul {
      width: 100%;
      display: none;
      background: #fff;
      padding: 0px;
      margin: 4px 0;
      z-index: 10;
      max-height: 205px;
      overflow-y: auto;
      position: absolute;
      left: 0;
      top: 100%;
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
      transition: all ease-in-out 0.5s;
      &.list {
        display: block;
      }
      li:not(.listbtn) {
        width: 100%;
        display: block;
        padding: 0 !important;
        border-bottom: 1px solid rgba(16, 31, 79, 0.07);
        button {
          width: 100%;
          display: block;
          font-family: 'HK Grotesk';
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          // text-transform: capitalize;
          color: #101F4F;
          padding: 10px 25px;
          background: #ffffff;
          border: none;
          text-align: left;
          transition: all ease-in-out 0.5s;
          cursor: pointer;
        }
        &.active {
          button {
            color: #ffffff;
            background: #007BFF;
            &:hover {
              color: #007BFF;
            }
          }
        }
        &:hover {
          button {
            // color: #ffffff;
            color: #007BFF;
            background: rgba(16, 31, 79, 0.04);
          }
        }
      }
      .listbtn{
        padding: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #007BFF;
        border-top: 1px solid rgba(16, 31, 79, 0.07);
        cursor: pointer;
        .icon{
          vertical-align: bottom;
          margin-right: 6px;
        }
      }
    }
  }
  .md {
    .tab_select {
      height: 32px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.005em;
      color: rgba(16, 31, 79, 0.45);
      padding: 9px 10px 8px;
    }
  }
.top {
    > button {
        border-radius: 10px 10px 0 0 !important;
    }    
}    
.bottom {
    > button {
        border-radius: 0 0 10px 10px !important;
    }    
    border: none !important;
}
.border {
    > button {
        border-radius: 10px !important;
    }
    border: none !important;
    
}
.selectdrowrap{
  margin-bottom: 27px;
  &.mb0 {
    margin-bottom: 0;
  }
}
// .label{
//   display: inline-block;
//   font-size: 16px;
//   line-height: 21px;
//   color: rgba(16, 31, 79, 0.75);
//   margin-bottom: 10px;
// }
.error_text {
  color: #EB5757;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  &.warning_text {
      color: #F2994A;
  }
}
.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: rgba(16, 31, 79, 0.75);
  display: block;
    margin: 0 0 10px;
}
.error{
  border: 1px solid #EB5757 !important;
}
.disable{
  opacity: .69;
  pointer-events: none !important;
}